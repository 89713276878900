.App, .WebClient, #root {
  height: 100%;
}

.Router {
  height: calc(100% - 24px);
}

html, body {
  margin:0px;
  height:100%;
}

.font-face-times {
    font-family: 'Times';
}

img {
  max-width: 100%;
}

tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

th,
td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {
  background: #f6f8fa;
}
