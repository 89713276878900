body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Times";
    src: local("Times"),
     url(/static/media/times.012b7b75.ttf) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Times";
    src: local("Times"),
     url(/static/media/timesbd.efcbd804.ttf) format("truetype");
    font-weight: bold;
}

.App, .WebClient, #root {
  height: 100%;
}

.Router {
  height: calc(100% - 24px);
}

html, body {
  margin:0px;
  height:100%;
}

.font-face-times {
    font-family: 'Times';
}

img {
  max-width: 100%;
}

tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

th,
td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {
  background: #f6f8fa;
}

label {
  text-transform:uppercase;
  font-weight: bold;
}
